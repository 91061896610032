import React, {Component, useContext} from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane, Nav, NavItem, NavLink, Col, Row, Input, Label, FormGroup } from 'reactstrap';

import { faPencilAlt, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavBar from '../layout/NavBar';
import Footer from '../layout/Footer';
import SideNavBar from '../layout/SideNavBar';

import axios from 'axios';

import './OnBoarding.css';

class OnBoarding extends Component 
{
    constructor(props) {

        super(props);

        this.state = {

            /*
            step1_Completed: false, 
            step2_Completed: false, 
            step3_Completed: false, 
            step4_Completed: false,
            step5_Completed: false,
            step6_Completed: false,
            step7_Completed: false,
            */

            stageFirstName: '',
            stageLastName:  '',
            legalFirstName: '',
            legalLastName:  '',

            team_name: '',
            tag_line: '',

            contactEmail:'',
            phone:'',
            suite:'',
            streetNumber:'',
            streetName:'',
            city:'',
            province:'',
            postalcode:'',
            country:'',

            talentsEmail:'',
            representativeEmail:'',

            agentCompanyName:'',
            agentName:'',
            agentPhone:'',
            agentSuite:'',
            agentStreetNumber:'',
            agentStreetName:'',
            agentCity:'',
            agentProvince:'',
            agentPostalcode:'',
            agentCountry:'',



            enterStageNameStep: false,   // stage name
            showStep2: false,   // legal name
            showStep3: false,   // legal name
            showStep4: false,   // team

            showTeamNameOpt: false,

            showTagLine: false,
            showAgency: false,

            // step 1 & 2
            sameLegalName: false,
            showStageNameVerification: false,
            stageNameVerified: false,
            stageNameConditionallyVerified: false,

            isComedyTeam: false,
            isAgencyRepresented: false,

            showContactEmail: false,
            showGDPR: false,
            showNotificationEmail: false,
            showAgencyDetails: false,
            showCongrats: false,


            currentDisplayingStatus: '',
            showModalCloseConfirmation: false,

        }

    }

    componentDidMount() {



        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'GET_SETUP_COMPLETED_NUMBER',
                
                

            }
        })
        .then(result => {
                      
            console.log("GET_SETUP_COMPLETED_NUMBER*******");
            console.log(result.data.completed_step);
            var completed_step = parseInt(result.data.completed_step);
            
            this.resumeAccountSetup(completed_step);
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );





        

    }

    resumeAccountSetup = (completed_step) => {

        if (completed_step == 0) {

            this.setState({enterStageNameStep: true});

            this.setState({currentDisplayingStatus: 'enterStageNameStep'});

        } else if (completed_step == 20) {

            this.setState({showTeamNameOpt: true});

            this.setState({currentDisplayingStatus: 'showTeamNameOpt'});

        } else if (completed_step == 40) {

            this.setState({showTagLine: true});

            this.setState({currentDisplayingStatus: 'showTagLine'});

        } else if (completed_step == 60) {

            this.setState({showAgency: true});

            this.setState({currentDisplayingStatus: 'showAgency'});

        } else {

            this.setState({enterStageNameStep: true});

            this.setState({currentDisplayingStatus: 'enterStageNameStep'});

        }
       


    }


    onInputChange = (event) => {

        this.setState({
            [event.target.name]: event.target.value
        });

    }


    // step 2
    gotoStep2 = () => {

               

        this.setState({enterStageNameStep: false});
        this.setState({showStep2: true});
        this.setState({showStep3: false});

        this.setState({currentDisplayingStatus: 'showStep2'});
        
            
        
    }

    isLegalNameSame = (same) => {

        var self = this;
        this.setState({sameLegalName: same});

        if (same) {
            self.setState({showStep2: false});

            self.setState({legalFirstName: this.state.stageFirstName});
            self.setState({legalLastName: this.state.stageLastName});

            setTimeout(function() {
                self.verifyStageName();
            }, 500);

            

        } else {
            self.setState({showStep2: false});
            self.setState({showStep3: true});

            self.setState({currentDisplayingStatus: 'showStep3'});
        }
        
    }


    verifyStageName = () => {

        var self = this;
        self.setState({showStageNameVerification: true});

        self.setState({currentDisplayingStatus: 'showStageNameVerification'});

        self.setState({showStep3: false});

        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                action: 'VALIDATE_NAME',
                stageFirst: this.state.stageFirstName,
                stageLast: this.state.stageLastName,
                legalFirst: this.state.legalFirstName,
                legalLast: this.state.legalLastName,
                

            }
        })
        .then(result => {
                       
            // onboarding_completed='20'

            console.log("---- VALIDATING NAME -----");
            console.log(result.data);

            setTimeout(function() {

                if (result.data.existing) {

                    self.setState({showStageNameVerification: false});
                    self.setState({stageNameConditionallyVerified: true});
                    
                } else {

                    self.setState({showStageNameVerification: false});
                    self.setState({stageNameVerified: true});

                    self.setState({currentDisplayingStatus: 'stageNameVerified'});
                    
                }
            
                
            }, 2000);
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

            


        
    }

    gotoStep4 = () => {

        /*
        this.setState({showStep3: false});
        this.setState({showStep4: true});
        */

    }

    
    showTeamNameOptModal = () => {

        
        this.setState({stageNameConditionallyVerified: false});
        this.setState({stageNameVerified: false});
        this.setState({showTeamNameOpt: true});

        this.setState({currentDisplayingStatus: 'showTeamNameOpt'});

    }


    isComedyTeamOpt = (isTeam) => {

        this.setState({isComedyTeam: isTeam});

    }


    showTagLineModal = () => {


        // Saving Team Name
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action: 'SAVE_TEAM_NAME',
                isTeam: this.state.isComedyTeam,
                teamName: this.state.team_name,
                
            }
        })
        .then(result => {
                       
            // onboarding_completed='40'

            console.log(result.data);

            this.setState({showTeamNameOpt: false});
            this.setState({showTagLine: true});

            this.setState({currentDisplayingStatus: 'showTagLine'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );



        

    }

    showAgencyModal = () => {

        

        // Saving Tag line
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action: 'SAVE_TAG_LINE',
                tagLine: this.state.tag_line
                               
            }
        })
        .then(result => {
                       
            // onboarding_completed='60'

            this.setState({showContactEmail: false});
            this.setState({showTagLine: false});
            this.setState({showAgency: true});

            this.setState({currentDisplayingStatus: 'showAgency'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        
        
        

    }
    
    isAgencyRepresentedOpt = (isAgency) => {

        this.setState({isAgencyRepresented: isAgency});

    }


    showContactEmailModal = () => {        
        
        

        // Saving isAgency Represented
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action: 'SAVE_AGENCY',
                isAgencyRepresented: this.state.isAgencyRepresented
                               
            }
        })
        .then(result => {
                       
            // onboarding_completed='80'

            this.setState({showGDPR: false});
            this.setState({showAgency: false});
            this.setState({showContactEmail: true});

            this.setState({currentDisplayingStatus: 'showContactEmail'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );



        

    }


    showGDPRModal = () => {
        
        // Saving Contact Email
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action: 'SAVE_CONTACT_EMAIL',
                contactEmail: this.state.contactEmail
                               
            }
        })
        .then(result => {
                       
            // contact_email_completed='100'

            this.setState({showNotificationEmail: false});
            this.setState({showContactEmail: false});
            this.setState({showGDPR: true});

            this.setState({currentDisplayingStatus: 'showGDPR'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );
        
        
        
    }


    showNotificationEmailModal = () => {


        // Saving GDPR
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action:         'SAVE_GDPR',
                phone:          this.state.phone,
                suite:          this.state.suite,
                streetNumber:   this.state.streetNumber,
                streetName:     this.state.streetName,
                city:           this.state.city,
                province:       this.state.province,
                postalCode:     this.state.postalcode,
                country:        this.state.country
                               
            }
        })
        .then(result => {
                       
            this.setState({showAgencyDetails: false});       
            this.setState({showGDPR: false});
            this.setState({showNotificationEmail: true});

            this.setState({currentDisplayingStatus: 'showNotificationEmail'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

               
        
        
    }

    showAgencyDetailsModal = () => {


        // Saving Notification Email
        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action:             'SAVE_NOTIFICATION_EMAIL',
                notifyEmail:        this.state.talentsEmail,
                agencyNotifyEmail:  this.state.representativeEmail
                
            }
        })
        .then(result => {
                       
            this.setState({showNotificationEmail: false});
            this.setState({showAgencyDetails: true});

            this.setState({currentDisplayingStatus: 'showAgencyDetails'});
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

        
        
    }

    showCongrats = () => { /*********************** */


        axios({
            method: 'post',
            url: 'https://laffnetdev.website/api/onboarding/index.php',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json' 
            },
            data: {
                
                action:             'SAVE_AGENCY_INFO',                
                agentCompanyName:   this.state.agentCompanyName,
                agentName:          this.state.agentName,
                agentPhone:         this.state.agentPhone,
                agentSuite:         this.state.agentSuite,
                agentStreetNumber:  this.state.agentStreetNumber,
                agentStreetName:    this.state.agentStreetName,
                agentCity:          this.state.agentCity,
                agentProvince:      this.state.agentProvince,
                agentPostalcode:    this.state.agentPostalcode,
                agentCountry:       this.state.agentCountry

            }   
        })
        .then(result => {
        
            this.setState({showAgencyDetails: false});
            this.setState({showCongrats: true});  
            
            this.setState({currentDisplayingStatus: 'showCongrats'});
            
            
        })
        .catch(error => 
            console.log("ERROR: "  + JSON.stringify(error) ) 
        );

        

   

    }

    gotoUploadHeadshot = () => {

        
        this.props.history.push("/headshot");



    }

    toggleModal = () => {
        this.setState({[this.state.currentDisplayingStatus]: false});

        this.setState({showModalCloseConfirmation: true});
        
    }

    continueSetup = () => {

        this.setState({showModalCloseConfirmation: false});
        this.setState({[this.state.currentDisplayingStatus]: true});

    }

    quitSetup = () => {

        this.props.history.push("/home-express");

    }

    render() {

        var self = this;

        const closeBtn = (
            <button className="close" onClick={() => this.toggleModal()} type="button">
              &times;
            </button>
          );

        return (
            <div style={backgroundStyle}>


                {/* Modal Close confirmation */}
                <Modal
                        isOpen={this.state.showModalCloseConfirmation}
                        size="lg"
                        backdrop={false}
                        centered
                        
                    >
                    <ModalHeader className="laff-blue" style={{}} > 
                        
                        Uh-oh! Hold your horses, you're not done yet...

                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color:'#ffffff'}}>                        
        
                        <br />                        
                        <p>Would you like to continue with your account setup or save your progress and exit?</p>
                        <br />

                        <div className="row">
                            <div className="col-6 text-left">
                                <button className="btn btn-sm btn-secondary" onClick={this.quitSetup}>Save & Exit</button>
                            </div>
                            <div className="col-6 text-right">
                                <button className="btn btn-primary btn-laff-sm-blue" onClick={this.continueSetup}>Continue</button>                            
                            </div>
                        </div>

                                                                                            
                    </ModalBody>
                    
                </Modal>





                
                {/* Step 1 */}
                <Modal
                        isOpen={this.state.enterStageNameStep}
                        size="lg"
                        backdrop={false}
                        centered
                        
                    >
                    <ModalHeader className="laff-blue" style={{}} close={closeBtn}> 
                        
                        Let's get to know you a little better...

                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <div style={{display: (!this.state.showStageNameVerification && !this.state.stageNameVerified) ? 'block':'none' }}>
                            
                            
                            
                            <br />
                            
                            <div>
                                <div className="mb-2"><input type="text" className="form-control form-control-sm" placeholder='Stage name first' name='stageFirstName' onChange={this.onInputChange} /></div>
                                <div className="mb-2"><input type="text" className="form-control form-control-sm" placeholder='Stage name last' name='stageLastName' onChange={this.onInputChange} /></div>
                            </div>

                            <div className="row">
                                
                                <div className="col-12 text-right" style={{paddingTop:'12px'}}>
                                    <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.gotoStep2}>Next {'>'}</button>
                                </div>
                            </div>


                            <div className="text-left">
                                <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                            </div>

                            
                        </div>


                        
                                                                                            
                    </ModalBody>
                    
                </Modal>


                {/* Step2 */}
                <Modal 
                        isOpen={this.state.showStep2}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Is this the name you go by legally?
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <br />

                        <div>
                            <div className="mb-2"><input type="text" className="form-control form-control-sm" value={this.state.stageFirstName} disabled/></div>
                            <div className="mb-2"><input type="text" className="form-control form-control-sm" value={this.state.stageLastName} disabled/></div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-gray-sm mr-1" onClick={() => this.isLegalNameSame(false)} >No</button>
                                <button className="btn btn-sm btn-laff-sm-blue" onClick={() => this.isLegalNameSame(true)} >Yes</button>
                            </div>
                            
                                                    
                        </div>

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>


                {/* Step3 */}
                <Modal 
                        isOpen={this.state.showStep3}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Hey, what's your full-on legal name?
                    </ModalHeader>

                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <br />

                        <div className="mb-2" style={{fontWeight:'bold', fontSize:'14px'}}>
                            For both legal & security purposes LaffNet needs your legal name. We do NOT publish or disclose your legal name. It's just between You and LaffNet.
                        </div>

                        <div>
                            <div className="mb-2"><input type="text" className="form-control form-control-sm" placeholder='Legal name first' name="legalFirstName" value={this.state.legalFirstName} onChange={this.onInputChange}/></div>
                            <div className="mb-2"><input type="text" className="form-control form-control-sm" placeholder='Legal name last' name="legalLastName" value={this.state.legalLastName} onChange={this.onInputChange}/></div>
                        </div>

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-gray-sm mr-1" onClick={this.gotoStep2} >{'<'} Back</button>
                                <button className="btn btn-sm btn-secondary btn-laff-sm-blue" onClick={this.verifyStageName} >Next {'>'}</button>
                            </div> 
                                                    
                        </div>

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>
                






                <Modal 
                        isOpen={this.state.showStageNameVerification && !this.state.stageNameVerified}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Hold tight, LaffNet is double-checking things!
                    </ModalHeader>

                    <ModalBody style={{backgroundColor:'#000000'}}>     

                        <div className="row">

                            <div className="col-12 text-center">
                                <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                            </div>
                            <br /><br />
                            
                            <div className="col-12" style={{color: '#ffffff'}}>
                                <p>It's important to protect our members from imposters. We are checking to ensure that the stage name you entered is not suspicious.</p>
                                <p>This may take a minute.</p>
                            </div>   
                                                    
                        </div>
                        <br />

                        
                                                                                 
                    </ModalBody>
                    
                </Modal>



                <Modal 
                        isOpen={!this.state.showStageNameVerification && this.state.stageNameVerified}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Hooray!
                    </ModalHeader>                    
                    <ModalBody style={{backgroundColor:'#000000'}}>                        

                        <div className="row">
                            
                            <div className="col-12 text-center">
                                <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                            </div>
                            <br /><br />

                            <div className="col-12" style={{color: '#ffffff'}}>
                                <p>You're clear to continue.</p>
                                <p>For your protection, and only if necessary, LaffNet may ask for additional identity verification in the future.</p>
                                <p>All is good for now.</p>
                                <p>Let's continue with your account set-up</p>
                            </div>

                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showTeamNameOptModal}>Next {'>'}</button>
                            </div>

                            
                                                    
                        </div>

                        
                                                                                 
                    </ModalBody>
                    
                </Modal>



                <Modal 
                        isOpen={!this.state.showStageNameVerification && this.state.stageNameConditionallyVerified}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Wow! You're Famous!
                    </ModalHeader>                    
                    <ModalBody style={{backgroundColor:'#000000'}}>

                        <div className="row">
                            
                            <div className="col-12">

                            <p>
                            We're thrilled to have you here but, for your security, we need some additional verification. There are two ways...
                            </p>

                            <p>
                            1-Send a Facebook Messenger from your Public Facebook Account to facebook.com/ LaffNet.Net saying..."Hey LaffNet. Stop being (Your Explitive Here). It's really me..."
                            </p>

                            <p>
                            2-Take a photo of a yourself and a valid Government issued photo ID. Then SMS both pics to xxx-xxx-xxxx.
                            </p>

                            <p>
                            Upon verification we'll activate your marketing features. In the meantime let's continue getting you set up.
                            </p>

                                
                            </div>

                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showTeamNameOptModal}>Next {'>'}</button>
                            </div>

                            <div className="text-left">
                                <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                            </div>
                            
                                                    
                        </div>

                        
                                                                                 
                    </ModalBody>
                    
                </Modal>




                <Modal 
                        isOpen={this.state.showTeamNameOpt}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Are you a one-person show or a dynamic duo?
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color: '#ffffff'}}>                        

                        <br />

                        <input type="radio" id="solo" name="comedy_team" value="solo" onChange={() => this.isComedyTeamOpt(false)} checked={!this.state.isComedyTeam} /> I'm a Solo Comedy Act<br />
                        <input type="radio" id="team" name="comedy_team" value="team" onChange={() => this.isComedyTeamOpt(true)} checked={this.state.isComedyTeam}/> We're a Comedy Team<br />

                        <br />
                        <div className="row" style={{display: this.state.isComedyTeam ? 'block' : 'none' }}>
                            <div className="col-12 laff-blue-txt">
                                <h4>What's the name for your comedy gang?</h4>
                            </div>
                            <br />
                            <div className="col-12">
                                <input type="text" id="team_name" name="team_name" className="form-control form-control-sm" placeholder="Enter Team Name (Max-50 Characters)" onChange={this.onInputChange}/>
                            </div>
                            <br />

                            
                        </div>

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showTagLineModal} >Next {'>'}</button>
                            </div>
                        </div>
                        
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>




                <Modal 
                        isOpen={this.state.showTagLine}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Hook us in with your act's slogan...
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color: '#ffffff'}}>                        
                        
                        <br />
                        <div className="row">

                            <div className="col-12">
                                Examples:<br /><br />
                                <i>It's going to get wild!</i><br />
                                <i>I really don't care!</i><br />
                                <i>Things are about to get uncomfortable!</i><br />
                            </div>
                            
                            <div className="col-12">
                                <input type="text" id="tag_line" name="tag_line" className="form-control form-control-sm" placeholder="Enter Tagline (Max 40 Characters)" onChange={this.onInputChange}/>
                            </div>
                            <br /><br />

                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showAgencyModal} >Next {'>'}</button>
                            </div>
                        </div>

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>



                <Modal 
                        isOpen={this.state.showAgency}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Are you flying solo or do you have a wingman?
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color: '#ffffff'}}>
                        
                        <br />
                        <div className="row">

                            <div className="col-12">
                                    
                                <input type="radio" id="self" name="agency_representation" value="self" onChange={() => this.isAgencyRepresentedOpt(false)} checked={!this.state.isAgencyRepresented} /> I am Self-Represented<br />
                                <input type="radio" id="agency" name="agency_representation" value="agency" onChange={() => this.isAgencyRepresentedOpt(true)} checked={this.state.isAgencyRepresented}/> I am booked through my Agency<br />

                            </div>
                            <br /><br />

                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showContactEmailModal} >Next {'>'}</button>
                            </div>
                        </div>

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>    
                                                                                 
                    </ModalBody>
                    
                </Modal>


                <Modal 
                        isOpen={this.state.showContactEmail}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        When someone wants YOU to light up the stage?
                    </ModalHeader>
                    <ModalBody style={{backgroundColor:'#000000', color: '#ffffff'}}>                        
                        
                        <br />
                        <div className="row">
                            
                            <div className="col-12">
                                Notify me at:<br />
                                <input type="text" className="form-control form-control-sm" placeholder="Email" name="contactEmail" value={this.state.contactEmail} onChange={this.onInputChange} />
                            </div>

                            
                        </div>

                        <br />

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-gray-sm mr-1" onClick={this.showAgencyModal} >{'<'} Back</button>
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showGDPRModal} >Next {'>'}</button>
                            </div>
                            
                        </div>
                        
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>


                <Modal 
                        isOpen={this.state.showGDPR}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Other Published Marketing Details
                    </ModalHeader>

                    <ModalBody style={{backgroundColor:'#000000'}}>

                        <br />
                        <div className="row">
                            
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Phone" name="phone" value={this.state.phone} onChange={this.onInputChange} />
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-4 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Unit" name="suite" value={this.state.suite} onChange={this.onInputChange} />
                            </div>
                            <div className="col-4 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Number" name="streetNumber" value={this.state.streetNumber} onChange={this.onInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Street Name" name="streetName" value={this.state.streetName} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="City" name="city" value={this.state.city} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Province/State" name="province" value={this.state.province} onChange={this.onInputChange} />
                            </div>
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Postal Code" name="postalcode" value={this.state.postalcode} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Country" name="country" value={this.state.country} onChange={this.onInputChange} />
                            </div>
                        </div>


                        <br />

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-gray-sm mr-1" onClick={this.showContactEmailModal} >{'<'} Back</button>
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showNotificationEmailModal} >Next {'>'}</button>
                            </div>
                        </div>

                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                        
                                                                                 
                    </ModalBody>
                    
                </Modal>
                


                <Modal 
                        isOpen={this.state.showNotificationEmail}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        When someone wants YOU to light up the stage?
                    </ModalHeader>

                    <ModalBody style={{backgroundColor:'#000000'}}>

                        <br />
                        <div className="row">
                            
                            <div className="col-12 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="talent's email address" name="talentsEmail" value={this.state.talentsEmail} onChange={this.onInputChange} />
                            </div>

                            <div className="col-12">
                                <input type="text" className="form-control form-control-sm" placeholder="representative's email address" name="representativeEmail" value={this.state.representativeEmail} onChange={this.onInputChange} />
                            </div>

                        </div>

                        


                        <br />

                        <div className="row">

                            <div className="col-6 text-left">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showGDPRModal} >{'<'} Back</button>
                            </div>
                            <div className="col-6 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={this.showAgencyDetailsModal} >Next {'>'}</button>
                            </div>

                        </div>
                        
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>





                <Modal 
                        isOpen={this.state.showAgencyDetails}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Insider deets about your agency and management please!
                    </ModalHeader>

                    <ModalBody style={{backgroundColor:'#000000'}}>
                        
                        <br />
                        <div className="row">
                            
                            <div className="col-12">
                                <input type="text" className="form-control form-control-sm" placeholder="Company Name" name="agentCompanyName" value={this.state.agentCompanyName} onChange={this.onInputChange} />
                                <br />
                                <input type="text" className="form-control form-control-sm" placeholder="Agent/Representative's Name" name="agentName" value={this.state.agentName} onChange={this.onInputChange} />
                                <br />
                                <input type="text" className="form-control form-control-sm" placeholder="Agent/Representative's Phone" name="agentPhone" value={this.state.agentPhone} onChange={this.onInputChange} />
                            </div>

                        </div>

                        <br />

                        <div className="row">
                            <div className="col-4 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Unit" name="agentSuite" value={this.state.agentSuite} onChange={this.onInputChange} />
                            </div>
                            <div className="col-4 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Number" name="agentStreetNumber" value={this.state.agentStreetNumber} onChange={this.onInputChange} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Street Name" name="agentStreetName" value={this.state.agentStreetName} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="City" name="agentCity" value={this.state.agentCity} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Province/State" name="agentProvince" value={this.state.agentProvince} onChange={this.onInputChange} />
                            </div>
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Postal Code" name="agentPostalcode" value={this.state.agentPostalcode} onChange={this.onInputChange} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6 mb-2">
                                <input type="text" className="form-control form-control-sm" placeholder="Country" name="agentCountry" value={this.state.agentCountry} onChange={this.onInputChange} />
                            </div>
                        </div>


                        <br />

                        <div className="row">
                            <div className="col-12 text-right">
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue mr-1" onClick={this.showNotificationEmailModal} >{'<'} Back</button>
                                <button className="btn btn-sm btn-primary btn-laff-sm-blue" onClick={ this.showCongrats } >Next {'>'}</button>
                            </div>

                        </div>
                        
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>


                <Modal 
                        isOpen={this.state.showCongrats}
                        size="lg"
                        backdrop={false}
                        centered
                    >
                    <ModalHeader className="laff-blue" close={closeBtn}>
                        Congratulations!
                    </ModalHeader>

                    <ModalBody >
                        
                        
                        <div className="row">

                            


                            <div className='col-12' style={{backgroundImage:'url(./img/bg_confetti.gif)'}}>

                                <br />
                                <h3>Congratulations!</h3>

                                <h5>Your LaffNet Profile has been created.</h5>

                                <p>You're so close to the finish line! Just a few more clicks and you'll be ready to unleash ultimate YOU onto the world. Build your brand, create your website & social media. Then... get ready to dazzle the masses with your awesomeness!</p>

                                <div className='text-right'>

                                    <button className='btn btn-laff' onClick={ this.gotoUploadHeadshot }>Let's Go! {'>'}</button>

                                </div>
                            </div>

                        </div>
                        
                        <div className="text-left">
                            <img src="img/LaffNet Logo Transparent.png" style={{width:'110px', height:'auto'}}/>
                        </div>
                                                                                 
                    </ModalBody>
                    
                </Modal>

                

            </div>
        );

    }
}


const backgroundStyle = {
    width:'100%',  
    height:'100vh',
    backgroundImage: `url(./img/laffnetbg_85.jpg)`, /* Location of the image */
	backgroundPosition: 'center center', /* Background image is centered vertically and horizontally at all times */
	backgroundRepeat: 'no-repeat', /* Background image doesn't tile */
	backgroundAttachment: 'fixed', /* Background image is fixed in the viewport so that it doesn't move when the content's height is greater than the image's height */
	backgroundSize: 'cover', /* This is what makes the background image rescale based on the container's size */
	backgroundColor: '#464646' /* Set a background color that will be displayed while the background image is loading */
}

export default OnBoarding;